<template>
  <div class="home-wrap">
    <div class="content-main">
      <div class="product-name">{{ productName }}</div>

      <div class="quota-block block">
        <img class="img-attach" src="/images/home/car@2x.png" />
        <p>最高额度（元）</p>
        <div class="highest-amt-num">{{ loanMaxAmount | amount }}</div>
        <div v-if="!specialProduct" class="amt-input">
          <van-field
            v-model="form.applyAmount"
            type="number"
            placeholder="请输入金额（元）"
          />
        </div>
        <van-field
          v-if="!specialProduct"
          v-model="texts.repaymentMethodStr"
          label="还款方式"
          placeholder="请选择"
          :rules="required"
          readonly
          required
          disabled
          is-link
          input-align="right"
        />
        <van-field
          v-model="texts.supportPeriod"
          label="期限"
          placeholder="请选择"
          :rules="required"
          @click="openPeriod()"
          readonly
          required
          is-link
          input-align="right"
        />
        <van-field
          v-model="texts.area"
          label="业务区域"
          placeholder="请选择"
          :rules="required"
          @click="regionPickerView = true"
          readonly
          required
          is-link
          input-align="right"
        />
      </div>

      <div class="apply-info block">
        <div class="info-title">申请信息</div>
        <van-field
          v-for="(block, idx) in infosFields"
          :key="idx"
          v-model="block.textvalue"
          :class="[block.complete ? 'end' : '']"
          :label="block.name"
          :placeholder="block.tips"
          @click="openFieldsEdit(block)"
          readonly
          :is-link="!block.complete"
          input-align="right"
        />
      </div>
    </div>

    <div class="btn page-btn">
      <van-button
        class="agree-btn"
        size="large"
        color="#1890FF"
        type="info"
        :loading="loading"
        @click="applyFn"
      >
        立即申请
      </van-button>
    </div>
    <!-- 征信授权确认弹层 -->
    <van-popup
      v-model="authShow"
      position="bottom"
      :close-on-click-overlay="false"
      :style="{ height: '80%' }"
    >
      <div class="auth-wrap">
        <div class="top">
          <!-- <van-icon name="cross" @click="authShow = false" /> -->
          <span>个人信息授权</span>
        </div>
        <div class="content">
          <div class="img-wrap">
            <img v-for="(img, i) in pdfImages" :key="i" :src="img" />
          </div>
        </div>
        <div class="btn page-btn">
          <van-button
            class="agree-btn"
            size="large"
            :loading="sendloading"
            color="#1890FF"
            type="info"
            :disabled="!!count"
            @click="sendAuthCode"
          >
            我已阅读并同意协议内容{{ `${count ? `（${count}s）` : ""}` }}
          </van-button>
        </div>
      </div>
    </van-popup>

    <PopupRegionPicker
      v-model="regionPickerView"
      @confirm="regionPickerConfirm"
      :code="lastcode"
      :keys="['provinceCode', 'cityCode']"
    />

    <!-- 短信验证 -->
    <van-popup class="verify-pop" v-model="verifyShow">
      <div class="verify-mask">
        <div class="verify-wrap">
          <div class="top">
            <van-icon name="cross" @click="verifyShow = false" />
            <span>短信验证</span>
          </div>
          <div class="content">
            <div class="tips">
              为确认以上操作为本人授权，需进行短信验证码校验，系统已给您发送验证码短信，请在下方输入短信验证码。
            </div>
            <div class="phone">
              <p>验证码已发送至</p>
              <p>{{ cacheData.phone | stringHypose }}</p>
            </div>
            <!-- 数字输入 -->
            <van-password-input
              :value="codevalue"
              :focused="focused"
              gutter="4px"
              :mask="false"
              @focus="inputFocus"
            />
            <div
              :class="['regain', regaincount ? 'dis' : '']"
              @click="regainFn"
            >
              重新获取验证码{{ `${regaincount ? `（${regaincount}s）` : ""}` }}
            </div>
          </div>
          <div class="btn page-btn">
            <van-button
              class="verify-btn"
              size="large"
              :loading="verifyloading"
              color="#1890FF"
              type="info"
              @click="verifyFn"
            >
              确认本人授权
            </van-button>
          </div>
        </div>

        <!-- 数字键盘 -->
        <van-number-keyboard
          :show="showKeyboard"
          @input="onInput"
          @delete="onDelete"
          @blur="showKeyboard = false"
        />
      </div>
    </van-popup>

    <!-- 选择弹层 -->
    <PopupPicker
      v-model="pickerView"
      :columns="pickerColumns"
      @confirm="pickerConfirm"
    />
    <!-- 填写信息弹层 -->
    <FillIn
      v-model="fillView"
      :blockKey="blockKey"
      :blockName="blockName"
      :blockFields="blockFields"
      :injectData="injectData"
      :cacheKeys="cacheKeys"
      :upperCaseKeys="upperCaseKeys"
      @updateData="updateData"
    />
  </div>
</template>

<script>
import * as PDFJS from "pdfjs-dist";
let pdfjsWorker = require("pdfjs-dist/build/pdf.worker.min");
import { areaList } from "@vant/area-data";
PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

import {
  productInfos,
  productConfigs,
  createOrder,
  authCode,
  checkedAuthCode,
  personApplyPdf,
  orderDetailLatest
} from "@/api";

import { getDetailLocation } from "@/api/location.js";
const required = [
  {
    required: true
  }
];

export default {
  name: "HomeIndex",
  components: {
    PopupPicker: () => import("./components/PopupPicker.vue"),
    FillIn: () => import("./components/FillInv2.vue"),

    PopupRegionPicker: () => import("./components/PopupRegionPicker.vue")
  },
  // 一个看似自动化的配置系统 却需要前端做大量的手动工作的项目
  data() {
    return {
      ossUrl: "", // pdf 地址
      pdfLoading: false,
      pdfImages: [],
      loanMaxAmount: 0, // 最高金额
      productName: "产品名称",
      texts: {}, // 选择field 绑定的文本值
      form: {}, // 选择field 真实key值
      fieldsForm: {}, // 所有字段信息
      pageLoading: false, //
      currentKey: "", // 当前 弹出层对应的key
      allenums: {}, // 所有枚举
      bizNo: "", // 生成的订单号
      accountNo: "", // 用户账号
      required,
      // 验证码
      codevalue: "", // 验证码
      focused: false, //
      showKeyboard: false,
      cacheData: {}, // 缓存信息
      authShow: false, //
      verifyShow: false,
      loading: false,
      sendloading: false,
      verifyloading: false,
      count: 5,
      regaincount: 90,
      pickerView: false,

      // 内部提取到外部的字段
      cacheKeys: ["customer_name", "cert_no"],
      // 填写的值需要大写处理的字段
      upperCaseKeys: ["car_licences", "vehicle_vin", "lice_id"],
      blockKey: "", // 填写信息对应的 sessionStorage key；每次编辑某个模块时变动
      blockName: "",
      blockFields: [],

      fillView: false, // 填写信息弹层
      pickerColumns: [], // 弹出层 选项
      areaInfo: {},
      lastcode: "",
      regionPickerView: false,
      infosFields: [] // 需要填写信息的模块及字段
    };
  },
  watch: {
    "form.applyAmount": async function (v) {
      try {
        await this.applyAmountCheck(v);
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // authShow(v) {
    //   if (v) {
    //   }
    // },
    pdfLoading(v) {
      if (v) {
        this.$toast.loading({
          message: "加载授权书...",
          forbidClick: true,
          loadingType: "circular"
        });
      } else this.$toast.clear();
    },
    cacheData: {
      deep: true,
      handler(data) {
        localStorage.setItem("_app_cache_data_", JSON.stringify(data));
      }
    }
  },
  created() {
    this.checkedIdentify();
  },
  mounted() {
    window.getPdfUrl = this.getPdfUrl;
    const data = localStorage.getItem("_app_cache_data_") || "{}";

    this.cacheData = JSON.parse(data);
    this.getProductInfos();
    this.getProductConfigs();
    this.ipLocation();
    this.judgeShowAuthDlg();
  },
  activated() {},
  computed: {
    // 向 FillIn 组件中填入的字段 （目前只有电话，不排除增加的可能，需要手动编辑）
    // 当该数组中对象的key等于 FillIn 中的字段key时，将该对象的value 填充进去
    injectData() {
      return [
        {
          key: "phone",
          value: this.cacheData.phone
        }
      ];
    },
    // ams25:申请页对指定的产品处理：隐藏掉申请金额、还款方式   前端可默认传：申请金额：0
    specialProduct() {
      const productNoArr = [
        "917830528319623168", // 黑户贷-测试/917830528319623168
        "1018918947929485312" // 生产产品 -吴凡
      ];
      const { productNo } = this.cacheData;
      return productNoArr.includes(productNo);
    }
  },
  methods: {
    // 判断是否打开授权弹窗
    async judgeShowAuthDlg() {
      try {
        const { productNo, merchantNo } = this.cacheData;
        await this.refreshCacheData(productNo, merchantNo);
        const { bizNo, hasAuth } = this.cacheData;
        if (hasAuth == "Y") {
          this.$router.replace(`/index?bizNo=${bizNo}`);
          return;
        }
        this.bizNo = bizNo || "";
        if (this.bizNo)
          this.$eventbus.$emit("verifyFollowTarget", {
            bizNo,
            callback: (res) => {
              if (res && res == "VERIFIED") {
                this.getPdfUrl();
              }
            }
          });
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // 检查是否认证
    // 存在订单号且未认证就跳转认证页面
    checkedIdentify() {
      const data = JSON.parse(localStorage.getItem("_app_cache_data_") || "{}");
      // const { bizNo, hasIdentification, isIdentification } = data;
      const { bizNo } = data;
      if (bizNo) {
        this.$eventbus.$emit("verifyFollowTarget", {
          bizNo,
          callback: () => {
            // if(res)
          }
        });
      }
      // 存在订单 需要四要素认证且未认证跳转认证页面
      // if (
      //   bizNo &&
      //   isIdentification == "Y" &&
      //   (hasIdentification != "Y" || !hasIdentification)
      // ) {
      //   this.$router.push("/identity");
      // }
    },
    // 获取pdf
    async getPdfUrl() {
      try {
        if (!this.bizNo) throw "订单编号错误";
        const res = await personApplyPdf(this.bizNo);
        this.ossUrl = res.data || "";
        this.pdfImages = await this.loadPDFSetImages();
        // 打开授权
        this.openAuth();
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // 区域选择确认
    regionPickerConfirm(codes, names) {
      const [provinceCode, cityCode] = codes;
      const [provinceName, cityName] = names;
      this.texts.area = `${provinceName}/${cityName}`;
      this.lastcode = cityCode;
      this.areaInfo = {
        cityCode,
        cityName,
        provinceName,
        provinceCode
      };
    },
    // 动态加载js
    loadJS(url, callback) {
      var script = document.createElement("script"),
        fn = callback || function () {};
      script.type = "text/javascript";
      if (script.readyState) {
        script.onreadystatechange = function () {
          if (
            script.readyState == "loaded" ||
            script.readyState == "complete"
          ) {
            script.onreadystatechange = null;
            fn();
          }
        };
      } else {
        script.onload = function () {
          /* eslint-disable */
          const data = window.localAddress;
          /* eslint-disable */
          fn(data);
        };
      }
      script.src = url;
      document.getElementsByTagName("head")[0].appendChild(script);
    },
    // 根据 城市信息反查 区域code
    async ipLocation() {
      try {
        const that = this;
        var lo = new T.Geolocation();

        const fn = function (e) {
          console.log("查看地图", e.lnglat);

          const lat = e?.lnglat?.lat;
          const lng = e?.lnglat?.lng;
          if (lat && lng) {
            that.getDetailLocation(lng, lat);
          } else {
            console.log("获取定位失败", e);
          }
        };
        lo.getCurrentPosition(fn);
      } catch (error) {
        this.$errMsg(error);
      }
    },
    async getDetailLocation(lng, lat) {
      try {
        const resp = await getDetailLocation(lng, lat);
        console.log("查看经纬度逆地址查询", resp);
        let data = resp?.data?.result?.addressComponent ?? {};
        // let data = testData?.result?.addressComponent ?? {};

        let cityCode =
          data.city_code && typeof data.city_code == "string"
            ? data.city_code.slice(3)
            : "";
        let provinceCode =
          data.province_code && typeof data.province_code == "string"
            ? data.province_code.slice(3)
            : "";

        console.log("匹配", provinceCode, cityCode);

        const [proName, cityName] = this.getAraeNameByCode(
          provinceCode,
          cityCode
        );
        this.areaInfo = {
          cityCode: cityCode,
          cityName: cityName,
          provinceName: proName,
          provinceCode: provinceCode
        };
        this.lastcode = cityCode;
        this.texts.area = `${proName}/${cityName}`;
      } catch (e) {
        console.log("e", e);
        this.$errMsg(error);
      }
    },
    // 根据 城市信息反查 区域code
    // （地址不明 暂时不用）
    async getCity() {
      this.loadJS("https://www.czhuihao.cn/ip", (data) => {
        const { province, city } = data;
        let pro_name = province
          ? province.replace(
              /省|市|区|县|壮族自治区|回族自治区|维吾尔自治区|自治区|/g,
              ""
            )
          : "";
        let city_name = city
          ? city.replace(
              /省|市|区|县|壮族自治区|回族自治区|维吾尔自治区|自治区|/g,
              ""
            )
          : "";
        const [pro_code, city_code] = this.getAraeCode(pro_name, city_name);

        this.areaInfo = {
          cityCode: city_code,
          cityName: city_name,
          provinceName: pro_name,
          provinceCode: pro_code
        };
      });
    },
    // 通过城市名称获取code
    getAraeCode(p, c) {
      const { province_list, city_list } = areaList;

      let c1, c2;
      for (let k in province_list) {
        if (province_list[k].includes(p)) {
          c1 = k;
          break;
        }
      }
      for (let k in city_list) {
        if (city_list[k].includes(c)) {
          c2 = k;
          break;
        }
      }
      return [c1, c2];
    },
    // 通过城市code获取城市name
    getAraeNameByCode(p, c) {
      const { province_list, city_list } = areaList;

      let c1 = province_list[p];
      let c2 = city_list[c];

      return [c1, c2];
    },

    // 刷新cacheData缓存
    async refreshCacheData(productNo, merchantNo) {
      const orderDetailResp = await orderDetailLatest(productNo, merchantNo);

      if (orderDetailResp && orderDetailResp.data) {
        const {
          bizNo,
          hasAuth,
          customerName,
          certNo,
          hasIdentification,
          isIdentification
        } = orderDetailResp.data;

        let jsonData = {
          ...this.cacheData,
          bizNo,
          hasAuth,
          customerName,
          certNo,
          hasIdentification, // 是否已经四要素认证
          isIdentification // 是否需要四要素认证
        };
        await localStorage.setItem(
          "_app_cache_data_",
          JSON.stringify(jsonData)
        );
        const data = (await localStorage.getItem("_app_cache_data_")) || "{}";
        this.cacheData = JSON.parse(data);
      }
    },
    // 暂时没用（他们可能会用）
    getPosition() {
      this.$toast.loading({
        message: "定位中...",
        forbidClick: true,
        loadingType: "circular"
      });
      navigator.geolocation.getCurrentPosition(
        (rst) => {
          const { latitude, longitude } = rst;
          this.$toast.clear();
        },
        (error) => {
          const code = error.code || -1;
          this.$toast.clear();
          try {
            switch (code) {
              case 1:
                throw "用户拒绝位置服务";
              case 2:
                throw "位置信息获取失败，请稍后重试";
              case 3:
                throw "获取位置超时";
              default:
                throw "未知错误";
            }
          } catch (err) {
            this.$toast.fail(err);
          }
        },
        {}
      );
    },
    // 金额校验
    applyAmountCheck(val) {
      const reg = /^\+?[1-9]\d*$/;
      if (!reg.test(val) || +val > +this.loanMaxAmount) {
        return Promise.reject("请输入正整数，且小于等于最高额度");
      } else {
        return Promise.resolve(true);
      }
    },
    // 产品信息
    async getProductInfos() {
      try {
        this.pageLoading = true;
        const { productNo } = this.cacheData;
        const { data } = await productInfos(productNo);
        const {
          repaymentMethodStr,
          supportPeriods,
          repaymentMethod,
          loanMaxAmount,
          productName
        } = data || {};
        this.loanMaxAmount = loanMaxAmount || 0;
        this.productName = productName || "产品名称";
        this.cacheData = {
          ...this.cacheData,
          repaymentMethod,
          repaymentMethodStr
        };
        const enums = (supportPeriods || []).map((value) => ({
          text: `${value} 期`,
          value
        }));
        this.$set(this.allenums, "supportPeriod", enums);
        this.texts.repaymentMethodStr = repaymentMethodStr;
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },
    // 产品配置
    async getProductConfigs() {
      try {
        this.pageLoading = true;
        const { productNo } = this.cacheData;
        const { data } = await productConfigs(productNo);
        // 筛选出能用的表单
        this.infosFields = data
          .filter((e) => {
            const _has = e.attributeDTOList && e.attributeDTOList.length;
            const _has2 = (e.attributeDTOList || []).filter(
              (e2) => e2.status == "Y"
            ).length;
            return _has && _has2;
          })
          .map((e) => {
            return {
              ...e,
              complete: false,
              name: e.qualificationName,
              textvalue: "",
              tips: "去完善"
            };
          });
        this.infosFields = [...this.infosFields];
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.pageLoading = false;
      }
    },

    // load pdf to images
    async loadPDFSetImages() {
      try {
        let url = this.ossUrl;
        if (!url) throw "申请书地址错误";
        this.pdfLoading = true;
        return new Promise((resp, rej) => {
          let self = this;
          self.pdfPicturePath = [];
          let arr = [];
          try {
            const loadingTask = PDFJS.getDocument(url);
            loadingTask.promise
              .then((pdf) => {
                if (pdf) {
                  // pdf 总页数
                  const pageNum = pdf.numPages;
                  for (let i = 1; i <= pageNum; i++) {
                    pdf.getPage(i).then((page) => {
                      const canvas = document.createElement("canvas");
                      const ctx = canvas.getContext("2d");
                      const viewport = page.getViewport({ scale: 2 });
                      canvas.height = viewport.height;
                      canvas.width = viewport.width;
                      const destWidth = 750;
                      canvas.style.width = destWidth + "px";
                      canvas.style.height =
                        destWidth * (viewport.height / viewport.width) + "px";
                      page.render({
                        canvasContext: ctx,
                        viewport
                      });
                      setTimeout(() => {
                        let url = canvas.toDataURL("image/png");
                        arr.push(url);
                        if (i === pageNum) {
                          self.pdfLoading = false;
                          resp(arr);
                        }
                      }, 1000);
                    });
                  }
                }
              })
              .catch((e) => {
                console.error("Error: " + e.message || e);
                self.pdfLoading = false;
                rej("Error: " + e.message || e);
              });
          } catch (error) {
            console.error("Error: " + error.message || error);
            self.pdfLoading = false;
            rej("Error: " + error.message || error);
          }
        });
      } catch (error) {
        return Promise.reject(error.message || error);
      }
    },
    // 弹出层确认
    pickerConfirm({ value, text }) {
      this.$set(this.texts, this.currentKey, text);
      this.$set(this.form, this.currentKey, value);
    },
    // 打开
    openPeriod() {
      this.currentKey = "supportPeriod";
      this.pickerColumns = this.allenums.supportPeriod;
      this.pickerView = true;
    },
    // 设置弹出层枚举
    setPopConfig(keyname) {
      this.currentKey = keyname;
      this.pickerColumns = this.allenums[keyname];
      this.pickerView = true;
    },
    // 打开编辑弹出层
    openFieldsEdit({ qualificationKey, qualificationName, attributeDTOList }) {
      this.blockKey = qualificationKey;
      this.blockFields = attributeDTOList;
      this.blockName = qualificationName;
      this.fillView = true;
    },
    openFieldsUploadEdit() {
      this.fillUpload = true;
    },

    // 更新本地信息
    updateData(key, data, cacheData) {
      let block = this.infosFields.find((e) => e.qualificationKey == key);
      const keys = {
        BASIC_INFORMATION: "basicInfomationMap", // 基础
        PROPERTY_INFORMATION: "propertyInfomationMap", // 房产
        VEHICLE_PRODUCTION_INFORMATION: "vehicleProductionInfomationMap",
        WORK_INFORMATION: "workInfomationMap",
        APPLY_EQUIPMENT: "applyEquipmentMap"
      };
      const index = this.infosFields.findIndex(
        (e) => e.qualificationKey == key
      );
      block.complete = true;
      // block.form = data;
      this.fieldsForm[keys[key]] = data;
      block.textvalue = "已完成"; // 展示用
      this.cacheKeys.map((key) => {
        this.$set(this.cacheData, key, cacheData[key] || this.cacheData[key]);
      });
      this.$set(this.infosFields, index, block);
    },

    // 验证信息
    // 生成订单
    async applyFn() {
      const unfill = this.infosFields.some((e) => !e.complete);
      console.log(this.infosFields);
      console.log(this.fieldsForm);
      const areafill = !!this.areaInfo && !!this.areaInfo.cityCode;
      if (!areafill) {
        this.$toast.fail("请选择业务区域");
        return;
      }
      if (unfill) {
        this.$toast.fail("请将申请信息填写完成");
        return;
      }
      this.createOrder();
    },
    // 生成订单
    async createOrder() {
      try {
        const {
          phone,
          productNo,
          merchantNo,
          agentNo,
          customerId,
          repaymentMethod
        } = this.cacheData;

        const params = {
          bizNo: this.bizNo,
          // 申请页对指定的产品处理：隐藏掉申请金额、还款方式   前端可默认传：申请金额：0
          applyAmount: this.specialProduct ? 0 : this.form.applyAmount,
          customerPhone: phone,
          applyProductNo: productNo,
          // 没心情做映射 就这样
          customerName: this.cacheData.customer_name,
          certNo: this.cacheData.cert_no,
          merchantNo,
          agentNo,
          applyTerm: this.form.supportPeriod,
          customerId,
          repaymentMethod,
          ...this.fieldsForm,
          // fieldInfoList,
          ...this.areaInfo
        };
        this.loading = true;
        this.$toast.loading({
          message: "创建订单...",
          forbidClick: true,
          loadingType: "circular"
        });

        const { data } = await createOrder(params);
        const bizNo = data || "";
        this.bizNo = bizNo;
        if (!this.bizNo) {
          throw "订单编号错误";
        }

        // 创建订单成功后，为了解决第一次申请进件时，没有订单编号，拿不到登录时上一个订单的缓存。
        // 这里在创建订单后刷新缓存

        await this.refreshCacheData(productNo, merchantNo);

        this.$set(this.cacheData, "bizNo", bizNo);
        this.$eventbus.$emit("verifyFollowTarget", {
          bizNo,
          callback: (res) => {
            if (res && res == "VERIFIED") {
              this.getPdfUrl();
            }
          }
        });
        // const { isIdentification } = this.cacheData;

        // if (isIdentification == "Y") {
        //   // TODO_XUS
        //   this.$router.push("/identity");
        // } else {
        //   this.getPdfUrl();
        // }
        // this.checkedIdentify();
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.loading = false;
      }
    },

    // 打开授权弹层
    openAuth() {
      this.authShow = true;
      if (this.count) {
        const ITV = setInterval(
          () => {
            this.count--;
            if (this.count <= 0) {
              this.count = 0;
              clearInterval(ITV);
            }
          },
          process.env.NODE_ENV === "development" ? 100 : 1000
        );
      }
    },

    // 发送授权验证码
    async sendAuthCode() {
      const { phone } = this.cacheData;
      try {
        this.sendloading = true;
        const { data } = await authCode({ mobile: phone, bizNo: this.bizNo });
        this.accountNo = data;
        this.$toast.success("验证码已发送，请注意查收！");
        this.verifyShow = true;
        this.setVerifyItv();
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.sendloading = false;
      }
    },
    // 重发验证码
    regainFn() {
      if (this.regaincount) return;
      this.sendAuthCode();
    },

    // 倒计时
    setVerifyItv() {
      this.regaincount = 90;
      const ITV = setInterval(
        () => {
          this.regaincount--;
          if (this.regaincount == 0) {
            clearInterval(ITV);
          }
        },
        process.env.NODE_ENV === "development" ? 20 : 1000
      );
    },

    // 提交授权验证码
    async verifyFn() {
      try {
        if (!this.codevalue) {
          throw "请输入验证码";
        }
        this.verifyloading = true;
        await checkedAuthCode({
          phone: this.cacheData.phone,
          smsCode: this.codevalue,
          bizNo: this.bizNo,
          ossUrl: this.ossUrl,
          accountNo: this.accountNo
        });
        this.$toast.success("提交成功");
        // this.$router.push({
        //   path: "/success"
        // });
        // 从原来的申请成功页面跳转到

        const { productNo, merchantNo } = this.cacheData;

        await this.refreshCacheData(productNo, merchantNo);
        this.$router.push({
          path: `/index?bizNo=${this.bizNo}`
        });
      } catch (error) {
        this.$errMsg(error);
      } finally {
        this.codevalue = "";
        this.verifyloading = false;
      }
    },

    //
    inputFocus() {
      this.focused = true;
      this.showKeyboard = true;
    },
    onInput(key) {
      this.codevalue = (this.codevalue + key).slice(0, 6);
    },
    onDelete() {
      this.codevalue = this.codevalue.slice(0, this.codevalue.length - 1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
